import React from "react";
import StyledTemplateView from "./TemplateView.styled";
import TemplateViewDetails from "./components/Details";
import TemplateViewFeatures from "./components/Features";
import TemplateViewDocs from "./components/Docs";
import TemplateViewSuggestions from "./components/Suggestions";

import { TemplateData } from "@gen/gatsby-graphql";

interface TemplateViewProps {
  template?: TemplateData;
  templates?: TemplateData[];
}

const TemplateView: React.FC<TemplateViewProps> = ({
  template,
  templates,
}) => {
  return (
    <StyledTemplateView>
      <TemplateViewDetails
        bigDescription={template?.description?.bigDescription}
        tech={template?.description?.tech}
        category={template?.description?.category}
        name={template?.name}
        phoneThumbnail={template?.thumbnailImages?.phoneThumbnail}
      />
      <TemplateViewFeatures
        features={template?.description?.features}
      />
      <TemplateViewDocs docs={template?.description?.docs} />

      <TemplateViewSuggestions suggestions={templates} />
    </StyledTemplateView>
  );
};

export default TemplateView;
