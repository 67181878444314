import styled from "styled-components";
import media from "@styles/media";

const DetailsImage = styled.div`
  z-index: 3;
  width: 250px;
  display: flex;
  align-items: center;
  flex: 2;
  height: auto;
  max-height: 80vh;
  margin-top: -20px;
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
  ${media.toLaptopMedium`
    max-height: 85vh;
  `}
  ${media.toLaptopSmall`
    max-height: 70vh;
  `}
  ${media.toTabletMedium`
    max-height: 60vh;
  `}
  ${media.toTabletP`
    display: none;
  `}
  img {
    width: 400px !important;
    margin-left: 50px;
    object-fit: contain;
  }
`;

export default DetailsImage;
