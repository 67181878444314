import styled from "styled-components";
import media from "@styles/media";

const DetailsBackgroundImage = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 600px;
  min-width: 300px;
  img {
    width: 350px;
  }
  ${media.toLaptopSmall`
    display: none;
  `}
`;

export default DetailsBackgroundImage;
