import React from "react";
import ReactMarkdown from "react-markdown";

import StyledDocs from "./Docs.styled";
import StyledDocsMain from "./components/DocsMain.styled";
import { H2 } from "@components/Typography";

interface DocsProps {
  docs?: any;
}

const Docs: React.FC<DocsProps> = ({ docs }) => {
  return (
    <StyledDocs>
      <H2>More details</H2>
      <StyledDocsMain isVisible={true}>
        <ReactMarkdown>{docs}</ReactMarkdown>
      </StyledDocsMain>
    </StyledDocs>
  );
};

export default Docs;
