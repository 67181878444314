import React, { useState } from "react";
import { navigate } from "gatsby";

const ConsultationDialog = () => {
  const [isHovered, setIsHovered] = useState(false);

  const handleClick = () => {
    navigate("/contact");
  };

  const containerStyle = {
    position: "fixed",
    bottom: "40px",
    left: "50%",
    transform: "translateX(-50%)",
    zIndex: 999,
    width: "400px",
    maxWidth: "90vw",
    display: "flex",
    justifyContent: "center",
    color: "white",
  };

  const buttonStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#4089ca",
    backgroundImage: "linear-gradient(to right, #4089ca, #2a5db0)",
    color: "white",
    padding: "14px 26px",
    borderRadius: "30px",
    width: "100%",
    maxWidth: "400px",
    boxShadow: isHovered
      ? "0 0 25px rgba(64, 137, 202, 0.7)"
      : "0 0 15px rgba(64, 137, 202, 0.5)",
    transition: "all 0.3s ease",
    border: "none",
    cursor: "pointer",
    position: "relative",
    overflow: "hidden",
  };

  const textContainerStyle = {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    zIndex: 1,
    color: "white",
  };

  const messageStyle = {
    fontSize: "14px",
    fontWeight: "400",
    color: "white",
  };

  const freeTextStyle = {
    fontSize: "14px",
    fontWeight: "700",
    color: "white",
  };

  const iconContainerStyle = {
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    borderRadius: "50%",
    padding: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "8px",
    transform: isHovered ? "translateX(5px)" : "translateX(0)",
    transition: "transform 0.3s ease",
    zIndex: 1,
    color: "white",
  };

  const rippleStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    width: isHovered ? "300%" : "0%",
    height: isHovered ? "300%" : "0%",
    transform: "translate(-50%, -50%)",
    borderRadius: "50%",
    backgroundColor: "#2a5db0",
    transition: "all 0.6s ease-out",
    zIndex: 0,
  };

  return (
    <div style={containerStyle}>
      <button
        style={buttonStyle}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={handleClick}
      >
        <div style={rippleStyle}></div>
        <div style={textContainerStyle}>
          <span style={messageStyle}>
            Need help with your React project?
          </span>
          <span style={freeTextStyle}>Get a FREE consultation</span>
        </div>
        <div style={iconContainerStyle}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      </button>
    </div>
  );
};

export default ConsultationDialog;
